import React,{useState} from 'react'

import addToMailchimp from 'gatsby-plugin-mailchimp'


const BlogSignIn = () => {

    const [firstname, setFirstname] = useState("")
    const [email, setEmail] = useState("")

    const mailChimpSubscription = (e) => {
        e.preventDefault()


        addToMailchimp(email, {
            FNAME: firstname
        }).then(data => {
            // if(data.result === "sucesss"){
                setEmail("")
                setFirstname("")
            // }

            alert(data.msg)

        })
    }

    return(
        <section className="bg-yellow max-w-screen-xl mx-auto sm:rounded-lg min-h-50 flex sm:mb-28 mb-12 px-12 justify-center  items-center">
            <div>
            <p className="sm:text-center max-w-screen-sm">Sign-up to receive in-time updates on our product, thoughts on investment among other many tips.</p>

                <form className="flex sm:flex-row flex-col sm:space-x-4 mt-8 w-full" onSubmit={mailChimpSubscription}>
                    <input onChange={(e)=>setFirstname(e.target.value)} value={firstname} type="text" placeholder="First name" 
                    className="px-4 py-3  font-sans sm:w-4/12 sm:mb-0 mb-3 outline-none border rounded"/>

                    <input onChange={(e)=>setEmail(e.target.value)} value={email} type="email" placeholder="Email address" 
                    className="px-4 py-3 font-sans sm:w-5/12 sm:mb-0 mb-3 border rounded"/>

                    <input type="submit" placeholder="Subscribe" 
                    className="p-3 sm:w-3/12 text-white cursor-pointer font-display font-display bg-torq-300 uppercase rounded"/>
                </form>
            </div>
            
        </section>
    )
}

export default BlogSignIn