import { Link } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'


const PostPreview = ({image, slug="", category, title, excerpt, date, length}) => {
    return(
        <article className="flex sm:flex-row-reverse sm:odd:flex-row flex-col px-12 max-w-screen-lg sm:my-28 my-12 mx-auto justify-between items-center">
            <header className="sm:w-6/12 w-full relative">
                <Img fluid={image} className="w-full" alt={title} />
                <span className="inline-block uppercase leading-wider p-2 absolute inline-block top-4 even:-right-4 odd:-left-4 bg-yellow sm:text-base text-xs tracking-wider">{category}</span>
            </header>
            <content className="sm:w-5/12 w-full ">
                <Link to={`/blog/${slug}`}><h2 className="text-torq-200">{title}</h2></Link> 
                <p className="-mt-2" dangerouslySetInnerHTML={{__html:excerpt }}></p>
                <footer className="text-torq-200 sm:text-base text-sm -mt-2 uppercase">{date}  |  {length} READ</footer>
            </content>
        </article>
    )
}

export default PostPreview
 