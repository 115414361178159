import React from 'react'
import Page from './page'

const Pagination = ({pages}) => {
    
    const allPages = []
    for (var i = 1; i<= parseInt(pages); i++){
        allPages.push(<Page key={i} label={i} link={`/blog/${i}`} />)
    }
    return(
        <section className="flex items-center mb-36">
            <section className="w-full"><hr  /></section>
            <section className="w-auto flex items-center -mt-6">
                {allPages}
            </section>
           
            <section className="w-full"><hr /></section>
        </section>
    )
}

export default Pagination