import React, { useState } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import Fade from 'react-reveal/Fade'

const BlogIntro = () => {

    const [firstname, setFirstname] = useState("")
    const [email, setEmail] = useState("")

    const mailChimpSubscription = (e) => {
        e.preventDefault()


        addToMailchimp(email, {
            FNAME: firstname
        }).then(data => {
            // if(data.result === "sucesss"){
                setEmail("")
                setFirstname("")
            // }

            alert(data.msg)

        })
    }


    
    return(
    
    <header className={`flex sm:h-96 sm:min-h-70 min-h-50 sm:py-24 py-6 items-center justify-center bg-yellow`}>
            <Fade bottom cascade>
        <div className="max-w-screen-xl  px-12 sm:text-center text-left">
                <h1 className="text-torq-200 max-w-screen-lg">Welcome to our Blog</h1>
                <p className="sm:w-9/12 w-full mx-auto max-w-screen-lg">Sign-up to receive in-time updates on our product, thoughts on investment among other many tips.</p>

                <form className="flex sm:flex-row flex-col sm:space-x-4 mt-8" onSubmit={mailChimpSubscription}>
                    <input onChange={(e)=>setFirstname(e.target.value)} value={firstname} type="text" placeholder="First name" 
                    className="px-4 py-3 font-sans sm:w-4/12 sm:mb-0 mb-3 border outline-none rounded"/>

                    <input onChange={(e)=>setEmail(e.target.value)} value={email} type="email" placeholder="Email address" 
                    className="px-4 py-3 font-sans sm:w-5/12 sm:mb-0 mb-3 border outline-none rounded"/>

                    
                    <input type="submit" value="Subscribe" 
                    className=" sm:w-3/12 p-3 text-white cursor-pointer font-display font-display bg-torq-200 uppercase rounded"/>
                </form>
        </div>
            </Fade>
    </header>
)}

export default BlogIntro