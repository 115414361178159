import React from "react"
import Pagination from "./pagination"
import PostPreview from "./post-preview"
import BlogIntro from "./blog-intro"
import BlogSignIn from "./sign-in"
import { graphql } from "gatsby"

import Layout from "../layout"
import SEO from "../seo"
// import { PageHeader } from '../components/utils'

export const postsQuery = graphql`
  query($skip: Int!, $numberOfPosts: Int!) {
    allWpPost(
      skip: $skip
      limit: $numberOfPosts
      filter: { featuredImage: { node: { fileSize: { gt: 0 } } } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          author {
            node {
              firstName
              lastName
            }
          }
          featuredImage {
            node {
              uri
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          title
          date(formatString: "DD MMMM YYYY")
          slug
          excerpt
          categories {
            nodes {
              name
            }
          }
        }
      }
    }
  }
`

const Blog = ({ data, pageContext }) => {
  const { numberOfPages } = pageContext
  const allPosts = data.allWpPost.edges
  console.log(numberOfPages)
  //Get the first four posts
  const topPosts = allPosts
    .filter((_, i) => i <= 3)
    .map(post => (
      <PostPreview
        key={post.node.uri}
        title={post.node.title}
        excerpt={post.node.excerpt}
        date={post.node.date}
        length=" 4 MIN"
        image={post.node.featuredImage.node.localFile.childImageSharp.fluid}
        category={post.node.categories.nodes[0].name}
        slug={post.node.slug}
      />
    ))

  //Get the last four posts
  const bottomPosts = allPosts
    .filter((_, i) => i > 3)
    .map(post => (
      <PostPreview
        key={post.node.uri}
        title={post.node.title}
        excerpt={post.node.excerpt}
        date={post.node.date}
        length=" 4 MIN"
        image={post.node.featuredImage.node.localFile.childImageSharp.fluid}
        category={post.node.categories.nodes[0].name}
        slug={post.node.slug}
      />
    ))

  return (
    <Layout color="yellow">
      <SEO title="Blog" />
      <BlogIntro />
      <section id="articles">{topPosts}</section>
      <BlogSignIn />
      <section>{allPosts.length > 4 ? bottomPosts : ""}</section>
      <Pagination pages={numberOfPages} />
    </Layout>
  )
}

export default Blog
